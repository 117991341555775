import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브 핀다이렉트샵 사전예약 알림 신청자 수로 미리 보는 아이폰13 고객반응",
  "| 이통사 공통 사전 예약 공략 타깃 ‘출고 지연’… 스테이지파이브는 1일 1만원, 최대 20만원 보상",
]

const content = () => (
  <div>
    <p>
      <br />
      <br />
      ‘혁신이 없다’는 아이폰13 시리즈, 국내에서 잘 될까? <br />
      스테이지파이브(대표 서상원)의 통신 판매 플랫폼 핀다이렉트샵이 15일(수) 부터 보름 간의 일정으로 진행 중인 사전예약 알림
      신청자 집계로 미루어 보자면, 아이폰 13은 국내에서도 통할 것으로 보인다.
      <br />
      <br />
      29일(수) 현재 핀다이렉트샵 아이폰13 사전예약 알림 신청자 사전 집계 결과, 애플의 전작인 아이폰12 시리즈의 사전예약 알림
      신청에 비해 220퍼센트 많은 것으로 나타났다. 사전예약 알림 신청 서비스 개시 닷새 만에 아이폰12 사전예약 알림 전체 신청 수치를
      초과할 만큼 증가세도 가파르다.
      <br />
      <br />
      이미 아이폰13 시리즈의 사전예약 판매를 시작한 중국에서 아이폰12보다 약 20%이상 주문량이 급증(미국 웨드부시 보고서 인용)한
      것과 같은 맥락이다.
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/24/ZItjTzWK.png" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure1" />
          <img src="/images/press/24/TpLKxWpm.png" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure1" />
        </div>
        <div className="desc">
          △ 오는 30일(목) 종료되는 핀다이렉트샵 아이폰13 사전예약 알림 신청자 수를 사전 집계한 결과, <br />
          전작 대비 220%를 웃도는 신청자가 몰린 것으로 나타났다. 왼쪽은 핀다이렉트샵 사전예약 홍보 이미지, <br />
          오른쪽은 핀다이렉트샵의 아이폰13과 아이폰12 사전예약 알림 누적 신청 추이를 비교한 표.
        </div>
      </div>
      <br />
      <br />
      핀다이렉트샵 ‘사전예약 알림’은 사전예약을 희망하는 고객을 위해 사전예약 개시일정에 맞춰 메시지 알림을 전송해주는 서비스이다.
      아이폰12 시리즈 런칭 당시 시작한 이 서비스는 당시에도 뜨거운 호응을 얻었는데, 이번에는 당시 수치의 두 배를 뛰어넘는 기록을
      올렸다.
      <br />
      <br />
      핀다이렉트샵 관계자는 “애플은 역시 애플인 것 같다. 삼성 Z플립3의 인기와 아이폰13 시리즈에 대한 혹평에 우려했지만, 오히려
      목표치를 뛰어넘을 만큼 고객 관심과 문의가 몰리고 있다. 5G 스마트폰 교체 수요와 애플 프리미엄 브랜드의 우위가 있는 만큼
      고객들이 발빠르게 혜택을 찾아 몰리는 것 같다”고 분석했다.
      <br />
      <br />
      아이폰13시리즈 3만원 할인권을 비롯하여 각종 혜택으로 구성된 ‘사전예약 알림’ 신청은 내일(30일) 종료된다.
      <br />
      <br />
      내달 1일(금)부터는 본격 사전 예약 판매에 들어간다. 사전예약 알림 신청을 통해 스마트폰으로 받은 카카오톡 메시지를 클릭하거나,
      핀다이렉트샵 (https://www.pindirectshop.com) 홈페이지 내 이벤트 게시판에서 신청하면 된다. 아이폰13 시리즈의 국내 정식 출시는
      오는 8일 (금)이다.
      <br />
      <br />
      <br />
      | 이통사 공통 아이폰13 사전예약자 공략점 ‘출고 지연’… 스테이지파이브는 최대 20만원상당 보상
      <br />
      <br />
      아이폰13 사전예약을 받는 국내 이동통신사들의 혜택에서 눈 여겨 볼만한 점은 이른바 ‘출고 지연’에 관한 우려를 경감시키기 위한
      접근법이다.
      <br />
      <br />
      빠른 배송서비스를 위해 사전예약자 전원에게 출시 당일 아이폰13을 배송하거나 선착순 1천명을 대상으로 출시일 0시 배송을
      시작하는 식이다.
      <br />
      <br />
      핀다이렉트샵은 출고지연 하루 당 1만원씩, 최대 20만원 상당의 보상금(백화점상품권)을 내걸었다.
      <br />
      <br />
      또한 사전예약 △자석형 무선 충전기 ‘맥 세이프(MagSafe)’ △카카오 쇼핑포인트 12만점 △웰컴 기프트 2종(어댑터, 폰케이스)
      △사용하던 스마트폰 반납 시 최대 20만원 추가 보상 등 기존 사전예약 혜택에 더하여 아이폰 13시리즈 3만원 할인권 및 애플 케어
      가입 즉시 5만원의 편의점 상품권 지급 혜택을 추가로 구성하여 사전예약에 도전하는 애플 마니아의 이목 잡기에 나선다.
      <br />
      <br />
    </p>
  </div>
)

const press24 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 9월 29일 즉시 배포 가능"
      pageInfo="*총 2매 / 첨부사진 2매"
      title="“아이폰은 아이폰!”…핀다샵 아이폰13 사전예약 알림 신청, 아이폰12대비 220% 웃돌아"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press24
